import Cookies from 'js-cookie'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { email, required } from 'vee-validate/dist/rules'

extend('requiredEmail', {
  ...required,
  message: 'Введите email'
})

extend('requiredPhone', {
  ...required,
  message: 'Введите телефон'
})

extend('email', {
  ...email,
  message: 'Введите корректный e-mail'
})

extend('requiredPassword', {
  ...required,
  message: 'Введите пароль'
})

export default {
  computed: {
    cookies () {
      return Cookies
    },
    signed () {
      return !!this.cookies.get(process.env.VUE_APP_COOKIE_CRED_NAME) || !!this.cookies.get(process.env.VUE_APP_COOKIE_AUTH_TAG)
    }
  },
  created () {
    // Redirect from auth routes to docs when signed
    if (this.signed) window.location = process.env.VUE_APP_DOCS_URL
  },
  components: {
    ValidationObserver,
    ValidationProvider
  }
}
